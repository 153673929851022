<template>
  <v-chip class="f2gChip" disabled label :color="color" :text-color="textColor">
    <F2gIcon
      :height="35"
      :stroke="iconColor"
      :strokeWidth="4"
      :value="icon"
      :width="27"
    />
    <span>{{ text.toUpperCase() }} </span>
  </v-chip>
</template>

<script>
export default {
  name: "F2gButton",
  props: {
    color: {
      required: true,
      type: String,
    },
    icon: {
      required: false,
      type: String,
    },
    iconColor: {
      default: "white",
      required: false,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
    textColor: {
      default: "white",
      required: false,
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.f2gChip {
  border-radius: 10px;
  font-size: 15px;
}
</style>